import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import UserIcon from '../../public/static/in_game_account_add_user_green.svg'
import { CategoryProfileResponse } from '../../types/users/category-profile-response'
import { Dictionary } from '../../types/collections/dictionary'
import { CategorySpecializedTag } from '../../types/users/requests/category-specialized-tag-response'
import { useTranslation } from 'next-i18next'
import EditIcon from '../../public/static/edit_gray_v2.svg'
import { useRecoilState } from 'recoil'
import { selectedGameState } from '../../common/atoms/atoms'
import groupBy from 'lodash.groupby'

type Props = {
  profiles: CategoryProfileResponse[] | undefined
}

export default function InGameAccountSectionV2({ profiles }: Props) {
  const [groupedProfile, setGroupedProfile] = useState<
    Dictionary<CategorySpecializedTag[]>
  >({})
  const { t } = useTranslation('inGameModal')
  const [profile, setProfile] = useState<CategoryProfileResponse>()
  const [hasDefault, setHasDefault] = useState(false)
  const [game] = useRecoilState(selectedGameState)

  useEffect(() => {
    setHasDefault(hasDefaultInGameAccount())
    let profile = profiles?.find((item) => {
      return item.isSelected
    })
    setProfile(profile)
    if (profile?.categorySpecializedTags) {
      setGroupedProfile(
        groupBy(profile.categorySpecializedTags, (item) => item.parent.name),
      )
    }
  }, [profiles])

  function hasDefaultInGameAccount(): boolean {
    return !!profiles?.find((item) => {
      return (
        item.isSelected &&
        item.username !== null &&
        item.categorySpecializedTags.find((tag) => {
          return tag.parent.name === 'Regions'
        })
      )
    })
  }

  return (
    <div className={'flex flex-col'}>
      <div className={'flex w-full cursor-pointer flex-row justify-between'}>
        <div className={'flex h-12 flex-row items-center'}>
          <div className={'relative h-6 w-6'}>
            {hasDefault ? (
              <Image
                src={
                  game?.iconImageAsset?.imageUrl ??
                  game?.imageAsset?.imageUrl ??
                  process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!
                }
                alt={game?.name ?? ''}
                className={'rounded-full object-contain'}
                fill={true}
              />
            ) : (
              <Image src={UserIcon} fill={true} alt={'User icon'} />
            )}
          </div>

          {hasDefault ? (
            <div className={'ml-3 flex flex-col'}>
              <p
                className={
                  'text-color-gray5 text-body-semibold w-52 truncate font-sans'
                }
              >
                {profile?.username}
              </p>
              <p
                className={'text-secondary-normal w-52 truncate text-darul-200'}
              >
                {groupedProfile['Regions']?.map((item) => {
                  return item.name
                })}

                {groupedProfile['Roles']?.length > 0 && (
                  <span className={'px-1'}>•</span>
                )}

                {groupedProfile['Roles']
                  ?.sort((obj1, obj2) => obj1.id - obj2.id)
                  ?.map((item) => item.name)
                  ?.join(', ')}

                {/*{groupedProfile['Rank']?.length > 0 && (*/}
                {/*  <span className={'px-1'}>•</span>*/}
                {/*)}*/}

                {/*{groupedProfile['Rank']?.map((item) => {*/}
                {/*  return item.name*/}
                {/*})}*/}
              </p>
            </div>
          ) : (
            <p className={'text-body-medium ml-2 text-white'}>
              {t('selectAccountTitle')}
            </p>
          )}
        </div>

        <div className={'flex flex-row items-center justify-between'}>
          <div className='relative mr-2'>
            <Image src={EditIcon} width={16} height={17} alt={'Editing icon'} />
          </div>
          <div className={'text-secondary-bold text-[#C8CFDE]'}>Edit</div>
        </div>
      </div>
    </div>
  )
}
